<template>
    <div v-loading="loading">
        <el-timeline v-if="!loading">
            <el-timeline-item v-for="(m, index) in messages" :key="index" :timestamp="m.Date" type="primary"
                :hollow="m.IsRead">
                <el-collapse>
                    <el-collapse-item name="1">
                        <template #title>
                            {{ m.Title }}
                        </template>
                        <div style="clear:both;white-space: pre-wrap;">
                            {{ m.Content }}
                        </div>

                        <!-- <el-tooltip v-if="m.IsRead" :content="$t('buttons.markUnread')" placement="bottom">
                        <el-button v-on:click="markUnread" type="primary" circle
                            style="clear:both;margin-top:10px;">
                            <span class="mdi mdi-email"></span>
                        </el-button>
                    </el-tooltip> -->
                        <el-tooltip v-if="!m.IsRead" :content="$t('buttons.markRead')" placement="bottom">
                            <el-button v-on:click="markRead(m.Id)" type="warning" circle
                                style="clear:both;margin-top:10px;">
                                <span class="mdi mdi-email-open"></span>
                            </el-button>
                        </el-tooltip>

                    </el-collapse-item>
                </el-collapse>
            </el-timeline-item>
        </el-timeline>
    </div>
    <router-link class="sp-list-link" v-if="showMore" :to="{ name: 'message' }">
        <el-button text type="primary">
            {{ $t('buttons.seeMore') }}... ({{ moreItemsCount }})
        </el-button>
    </router-link>

</template>

<script>
import $ from 'jquery'
import { List } from '@/scripts/list'
import { Notifications } from '@/scripts/notifications'
import { Logger } from '@/scripts/logger'
import * as api from '@/api/MessageApi'

export default {
    name: 'MessagesTimeLine',
    mixins: [List],
    props: [],
    data() {
        return {
            //component configuration
            pageSize: 25,
            apiUrl: '/api/message/',
            showOnlyNotifyResult: true,

            //buttons
            showMore: false,
            moreItemsCount: 0,
        };
    },
    mounted() {
        this.fetchList();
    },
    computed: {
    },
    methods: {
        fetchAfterSave: function () {
            this.fetchList();
            this.$root.getMessageCounter();
        },
        fetchList: function () {
            let self = this;

            this.loading = true;

            if (JSON.stringify(this.searchModel) !== JSON.stringify(this.searchModelCopy)) {
                this.pageIndex = 1; //jedynkować pageIndex jeśli zmienia się searchModel
            };

            if (!this.pageIndex) this.pageIndex = 1;

            this.messages = [];

            api.getPage({
                searchModel: JSON.stringify($.extend(true, {}, this.searchModel)),
                pageIndex: self.pageIndex,
                pageSize: self.pageSize
            },
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        this.loading = false;
                        return;
                    }

                    this.model = response.data.Model;

                    //this.searchModel = this.model.SearchModel;
                    this.searchModelCopy = $.extend(true, {}, this.searchModel);

                    this.total = this.model.count;
                    this.messages = this.model.data;

                    if (this.total > this.pageSize) {
                        self.showMore = true;
                        self.moreItemsCount = this.total - this.pageSize;
                    };

                    $('.list_loadtime').text(this.model.loadTime);

                    this.$refs.messageTable?.doLayout();

                    this.loadOperations();

                    $('.d_legendMessage').show();
                    this.setTableHeight();

                    this.loading = false;
                },
                (error) => {
                    Logger.logException(error);

                    this.setTableHeight();
                    this.loading = false;
                }
            );
        },

        //operations
        loadOperations: function () {
            this.prepareOperations(this.model);
        },
        markRead: function (messageId) {
            this.selectedRows = [{ Id: messageId }];
            this.handleMenuCommand('OpMessageSetAsRead');
        },

        // showMessageDialog: function (message) {
        //     var title = message.Topic;
        //     var text = message.Message;

        //     ElMessageBox.alert(text, title, {
        //         confirmButtonText: 'OK'
        //     })
        // },
    },
}
</script>