<template>
    <el-menu mode="horizontal" class="sp-client-header" :ellipsis="($root.screenSize < $root.ellipsisNavbarScreenMaxSize)">
        <el-menu-item index="0" class="menu-item-logo">
            <div
                style="background-color:var(--enea-color-darkblue);width:200px;text-align:center;margin-left:-30px;height:62px;">
                <router-link to="/" style="display:block;margin-left:0px;padding-left:12px;padding-bottom:8px;">
                    <el-image :src="require('@/assets/images/Enea.logo.png')" />
                    <!-- <el-image class="sp-client-header-logo-white" :src="require('@/assets/images/Enea.logo.png')" />
                    <el-image class="sp-client-header-logo-darkblue" :src="require('@/assets/images/enea.logo.dark.png')" /> -->
                </router-link>
            </div>
        </el-menu-item>

        <el-menu-item index="1">
            <span class="sp-client-header-navbar-title">
                <span>Enea Trade24</span>
                <span class="sp-client-header-navbar-subtitle-big"> - {{ $t('headers.NavbarTitle') }}</span>
                <br class="sp-client-header-navbar-subtitle-small" />
                <span class="sp-client-header-navbar-subtitle-small" style="font-size:12px;line-height:12px;float:left;">{{
                    $t('headers.NavbarTitle')
                }}</span>
            </span>
        </el-menu-item>

        <div class="flex-grow"></div>

        <el-menu-item index="1" class="menu-hamburger">
            <a v-on:click="appvue.toggleCollapse()"><span class="mdi mdi-menu"></span></a>
        </el-menu-item>

        <el-menu-item index="6" class="sp-header-badge hidden-xxs-only">
            <el-badge :value="$root.messageCounter" :max="99" class="item" v-on:click="$root.drawer = !$root.drawer;">
                <span class="mdi mdi-comment-outline" style="font-size:28px;"></span>
            </el-badge>
        </el-menu-item>

        <el-sub-menu index="3" class="sp-client-header-language hidden-xxs-only">
            <template #title>
                <el-avatar v-if="this.$i18n.locale == 'pl-PL'" fit="cover" size="small"
                           :src="require('@/assets/images/Languages/pl-PL.png')" />
                <el-avatar v-if="!this.$i18n.locale || this.$i18n.locale == 'en-GB'" fit="cover"
                           size="small" :src="require('@/assets/images/Languages/en-GB.png')" />
            </template>
            <el-menu-item index="3-1" v-on:click="appvue.setPL">Polski (pl-PL)</el-menu-item>
            <el-menu-item index="3-2" v-on:click="appvue.setEN">English (en-GB)</el-menu-item>
        </el-sub-menu>

        <el-menu-item index="4" class="sp-client-header-user hidden-xxs-only">
            <template #title>
                <el-popover :width="300" trigger="click"
                    popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;">
                    <template #reference>
                        <span v-if="$root.initials" class="sp-client-header-user-initials">
                            {{ $root.initials }}
                        </span>
                        <span v-else class="sp-client-header-user-initials" style="background-color: #bbb;">
                            <span class="mdi mdi-login"></span>
                        </span>
                    </template>
                    <template #default>
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24">
                                <div v-if="$root.user.name" style="text-align:center;font-size: 22px;">
                                    {{ $root.user.name }}
                                </div>
                            </el-col>
                        </el-row>

                        <el-divider></el-divider>

                        <div v-if="$root.user.isAuthenticated">
                            <el-row>
                                <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                                    <el-button type="warning" @click="$root.changePassword">
                                        {{ $t('toolbar.changePassword') }}
                                    </el-button>
                                </el-col>
                                <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                                    <el-button type="primary" @click="$root.logoutUser" class="pull-right">
                                        {{ $t('toolbar.logout') }}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>

                        <div v-if="!$root.user.isAuthenticated">
                            <el-button type="primary" @click="$root.loginUser">
                                {{ $t('toolbar.login') }}
                            </el-button>
                        </div>


                    </template>
                </el-popover>
            </template>
        </el-menu-item>

    </el-menu>
</template>

<script>


export default {
    name: 'HeaderMenu',
    props: ['appvue'],
    computed: {

    },
    mounted() {
    },
    methods: {

    }
}
</script>
