<template>

</template>

<script>

    import { BroadcastChannel } from 'broadcast-channel';

    export default {
        name: "MultiTabPrevent",

        props: {
            notifyFirstTabFn: Function,
            notifySecondTabFn: Function
        },

        data: function () {
            return {
                validateFirstTab: "VALIDATE",
                error: "ERROR"
            }
        },

        mounted() {
            let self = this;
            const bc = new BroadcastChannel("mtPrevent");
            bc.onmessage = (event) => {
                if (event == self.validateFirstTab) {
                    self.notifyFirstTabFn();
                    bc.postMessage(self.error);
                }
                if (event == self.error) {
                    self.notifySecondTabFn();
                }
            };

            bc.postMessage(self.validateFirstTab);
        },
    }
</script>