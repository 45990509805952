<template>
    <el-drawer v-model="$root.drawer" :title="$t('headers.rightDrawer')" :destroy-on-close="true">
        <MessagesTimeLine></MessagesTimeLine>

        <!-- <el-calendar>
            <template #dateCell="{ data }" v-model="calendar">
                <p :class="data.isSelected ? 'is-selected' : ''">
                    {{ data.day.split('-').slice(1).slice(1).join('-') }}
                    {{ data.day == calendar.day ? 'OK' : '' }}
                </p>
            </template>
        </el-calendar> -->

    </el-drawer>
</template>

<script>
import MessagesTimeLine from '../components/MessagesTimeLine'

export default {
    name: 'RightDrawer',
    props: [],
    data() {
        return {
            calendar: {
                day: '2022-07-18'
            },
        };
    },
    components: {
        MessagesTimeLine
    }
}
</script>