<template>
    <el-menu class="sp-client-left-sidebar-menu" background-color="transparent" :collapse="collapseSidebar"
        :default-active="activeMenu" :unique-opened="true">

        <el-menu-item index="home">
            <el-icon>
                <span class="mdi mdi-home"></span>
            </el-icon>
            <span>
                <router-link style="margin-left: 0px;" to="/">{{ $t('pages.Home') }}</router-link>
            </span>
        </el-menu-item>

        <el-sub-menu index="energy" v-if="!Helpers.isNullOrEmpty($root.menuInformationEE)">
            <template #title>
                <el-icon>
                    <span class="mdi mdi-flash"></span>
                </el-icon>
                <span>{{ $t('menu.energy') }}</span>
            </template>
            <el-menu-item index="energy-tranchePurchase">
                <span>
                    <el-button text style="background-color: transparent; color: var(--enea-color-darkblue);"
                        @click="$root.contractForm = { 'ContractId': '', 'MediumType': 'EE' }; $root.dialogNewTrancheVisible = true">
                        {{ $t('menu.tranchePurchase') }}
                    </el-button>
                </span>
            </el-menu-item>
            <el-menu-item index="energy-notificationMandate">
                <span>
                    <el-button text style="background-color: transparent; color: var(--enea-color-darkblue);"
                        @click="$root.contractForm = { 'ContractId': '', 'MediumType': 'EE' }; $root.dialogNewMandateVisible = true">
                        {{ $t('menu.notificationMandate') }}
                    </el-button>
                </span>
            </el-menu-item>
            <el-menu-item index="energy-contracts">
                <span>
                    <router-link to="/contract/EE">{{ $t('menu.contracts') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="energy-history">
                <span>
                    <router-link to="/vtransaction/EE">{{ $t('pages.VTransaction') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="energy-offer" v-if="$root.menuInformationEE?.HasOffers > 0">
                <el-badge :value="$root.offerCounter" :max="99" class="item sidebar-item">
                    <span>
                        <router-link to="/offer">{{ $t('menu.offer') }}</router-link>
                    </span>
                </el-badge>
            </el-menu-item>
            <el-menu-item index="energy-activeMandates" v-if="$root.menuInformationEE?.HasMandates > 0">
                <span>
                    <router-link to="/activemandates/EE">{{ $t('menu.activeMandates') }}</router-link>
                </span>
            </el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="gas" v-if="!Helpers.isNullOrEmpty($root.menuInformationGAS)">
            <template #title>
                <el-icon>
                    <span class="mdi mdi-fire"></span>
                </el-icon>
                <span>{{ $t('menu.gas') }}</span>
            </template>
            <el-menu-item index="gas-tranchePurchase">
                <span>
                    <el-button text style="background-color: transparent; color: var(--enea-color-darkblue);"
                        @click="$root.contractForm = { 'ContractId': '', 'MediumType': 'GAS' }; $root.dialogNewTrancheVisible = true">
                        {{ $t('menu.tranchePurchase') }}
                    </el-button>
                </span>
            </el-menu-item>
            <el-menu-item index="gas-notificationMandate">
                <span>
                    <el-button text style="background-color: transparent; color: var(--enea-color-darkblue);"
                        @click="$root.contractForm = { 'ContractId': '', 'MediumType': 'GAS' }; $root.dialogNewMandateVisible = true">
                        {{ $t('menu.notificationMandate') }}
                    </el-button>
                </span>
            </el-menu-item>
            <el-menu-item index="gas-contracts">
                <span>
                    <router-link to="/contract/GAS">{{ $t('menu.contracts') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="gas-history">
                <span>
                    <router-link to="/vtransaction/GAS">{{ $t('pages.VTransaction') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="gas-activeMandates" v-if="$root.menuInformationGAS?.HasMandates > 0">
                <span>
                    <router-link to="/activemandates/GAS">{{ $t('menu.activeMandates') }}</router-link>
                </span>
            </el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="pm" v-if="!Helpers.isNullOrEmpty($root.menuInformationPR)">
            <template #title>
                <el-icon>
                    <span class="mdi mdi-file-document-edit-outline"></span>
                </el-icon>
                <span>{{ $t('menu.pm') }}</span>
            </template>
            <el-menu-item index="pm-tranchePurchase">
                <span>
                    <el-button text style="background-color: transparent; color: var(--enea-color-darkblue);"
                        @click="$root.contractForm = { 'ContractId': '', 'MediumType': 'PR' }; $root.dialogNewTrancheVisible = true">
                        {{ $t('menu.tranchePurchase') }}
                    </el-button>
                </span>
            </el-menu-item>
            <el-menu-item index="pm-contracts">
                <span>
                    <router-link to="/contract/PR">{{ $t('menu.contracts') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="pm-history">
                <span>
                    <router-link to="/vtransaction/PR">{{ $t('pages.VTransaction') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="pm-activeMandates" v-if="$root.menuInformationPR?.HasMandates > 0">
                <span>
                    <router-link to="/activemandates/PR">{{ $t('menu.activeMandates') }}</router-link>
                </span>
            </el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="other">
            <template #title>
                <el-icon>
                    <span class="mdi mdi-link"></span>
                </el-icon>
                <span>{{ $t('menu.other') }}</span>
            </template>
            <el-menu-item index="other-alerts">
                <span>
                    <router-link to="/alerts">{{ $t('menu.alerts') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="other-messages">
                <span>
                    <router-link to="/message">{{ $t('menu.messages') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="other-documents">
                <span>
                    <router-link to="/documents">{{ $t('menu.documents') }}</router-link>
                </span>
            </el-menu-item>
            <el-menu-item index="other-authorization">
                <span>
                    <!--<router-link to="/authorization">{{ $t('menu.authorization') }}</router-link>-->
                    <el-button text style="background-color: transparent; color: var(--enea-color-darkblue);"
                               @click="$root.contractForm = { 'ContractId': '' }; $root.dialogSelectContractVisible = true">
                        {{ $t('menu.authorization') }}
                    </el-button>
                </span>
            </el-menu-item>
        </el-sub-menu>

        <el-menu-item index="contact">
            <el-icon>
                <span class="mdi mdi-comment-processing-outline"></span>
            </el-icon>
            <span>
                <!-- <router-link style="margin-left: 0px;" to="/contact">{{ $t('menu.contact') }}</router-link> -->
                <a style="margin-left: 0px;" :href="$root.$router.resolve({name: 'contact'}).href">{{ $t('menu.contact') }}</a>
            </span>
        </el-menu-item>


        <!-- te pozycje zawsze na końcu -->

        <el-menu-item index="header-1" class="sp-header-badge hidden-xxs-up">
            <el-badge :value="$root.messageCounter" :max="99" class="item" v-on:click="$root.drawer = !$root.drawer;">
                <el-icon><span class="mdi mdi-comment-outline"></span></el-icon>
            </el-badge>
            <template #title>
                <span v-on:click="$root.drawer = !$root.drawer;">{{ $t('headers.Messages') }}</span>
            </template>
        </el-menu-item>

        <el-sub-menu index="header-2" class="hidden-xxs-up">
            <template #title>
                <el-avatar v-if="this.$i18n.locale == 'pl-PL'" fit="cover" size="small"
                    style="width:16px;height:16px;margin-right:12px;margin-left:5px;"
                    :src="require('@/assets/images/Languages/pl-PL.png')" />
                <el-avatar v-if="!this.$i18n.locale || this.$i18n.locale == 'en-GB'" fit="cover" size="small"
                    style="width:16px;height:16px;margin-right:12px;margin-left:5px;"
                    :src="require('@/assets/images/Languages/en-GB.png')" />
                {{ $t('menu.ChangeLanguage') }}
            </template>
            <el-menu-item index="header-2-1" v-on:click="$root.setPL">
                <span>
                    <a>Polski (pl-PL)</a>
                </span>
            </el-menu-item>
            <el-menu-item index="header-2-2" v-on:click="$root.setEN">
                <span>
                    <a>English (en-GB)</a>
                </span>
            </el-menu-item>
        </el-sub-menu>

        <el-menu-item index="header-3" class="sp-client-header-user hidden-xxs-up">
            <template #title>
                <el-popover :width="Renderers.numberSize(300, 300, 300, 300, 300, 230, 300)" trigger="click" placement="top"
                    popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;">
                    <template #reference>
                        <span v-if="$root.initials" class="sp-client-header-user-initials">
                            {{ $root.user.name }}
                        </span>
                        <span v-else class="sp-client-header-user-initials" style="background-color: #bbb;">
                            <span class="mdi mdi-login"></span>
                        </span>
                    </template>
                    <template #default>
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24">
                                <div v-if="$root.user.name" style="text-align:center;font-size: 22px;">
                                    {{ $root.user.name }}
                                </div>
                            </el-col>
                        </el-row>

                        <el-divider></el-divider>

                        <div v-if="$root.user.isAuthenticated">
                            <el-row>
                                <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                                    <el-button type="warning" @click="$root.changePassword">
                                        {{ $t('toolbar.changePassword') }}
                                    </el-button>
                                </el-col>
                                <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                                    <el-button type="primary" @click="$root.logoutUser" class="pull-right">
                                        {{ $t('toolbar.logout') }}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>

                        <div v-if="!$root.user.isAuthenticated">
                            <el-button type="primary" @click="$root.loginUser">
                                {{ $t('toolbar.login') }}
                            </el-button>
                        </div>


                    </template>
                </el-popover>
            </template>
        </el-menu-item>

        <!-- <el-menu-item index="999">
            <el-icon><span class="mdi mdi-monitor-screenshot"></span></el-icon>
            <template #title>
                {{ $root.actualScreenSizeResponsive }}: {{ $root.actualScreenSize }}px
            </template>
        </el-menu-item> -->

    </el-menu>
</template>

<script>

import { Helpers } from '@/scripts/helpers'
import { Renderers } from '@/scripts/renderers'

export default {
    name: 'SidebarMenu',
    props: ['collapseSidebar', 'appvue'],
    setup() {
        return { Helpers, Renderers };
    },
    mounted() {

    },
    data() {
        return {

        };
    },
    methods: {

    },
    computed: {
        activeMenu() {
            var pathName = window.location.pathname;
            var currentMenu = pathName.split('/')[1];
            return currentMenu;
        },
    },
}
</script>
