<template>

</template>

<script>

    import { ElNotification } from 'element-plus'

    export default {
        name: "AutoLogout",
        props: {
            warningHeader: String,
            warningMessage: String,
            warningTime: Number,
            logoutTime: Number,
            logoutFn: Function
        },

        data: function () {
            return {
                events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],

                warningNotification: null,

                warningTimer: null,
                logoutTimer: null
            }
        },

        mounted() {
            this.events.forEach(function (event) {
                window.addEventListener(event, this.resetTimer)
            }, this);

            this.setTimer();
        },

        methods: {

            setTimer: function () {
                this.warningTimer = setTimeout(this.warning, this.warningTime * 60 * 1000);
                this.logoutTimer = setTimeout(this.logout, this.logoutTime * 60 * 1000);
            },

            warning: function () {
                this.warningNotification = ElNotification({
                    title: this.warningHeader,
                    message: this.warningMessage,
                    duration: 0,
                    type: 'warning'
                });
            },

            logout: function () {
                this.logoutFn();
            },

            resetTimer: function () {
                clearTimeout(this.warningTimer);
                clearTimeout(this.logoutTimer);
                if (this.warningNotification != null)
                    this.warningNotification.close();
                this.setTimer();
            },

        }
    }
</script>