<template>
    <el-config-provider :locale="locale">
        <div id="appLayout" class="sp-client-header-white sp-client-sidebar-transparent vue-app" style="display:none;"
            v-loading="loadingPage">
            <el-container>
                <el-header class="sp-main-header">
                    <HeaderMenu :appvue="this">
                    </HeaderMenu>
                </el-header>
                <el-container class="sp-main-container">
                    <el-aside class="sp-client-left-sidebar">
                        <SidebarMenu :collapseSidebar="collapseSidebar" :appvue="this"></SidebarMenu>
                    </el-aside>
                    <el-main class="sp-el-main-app">
                        <div class="sp-main-app">
                            <AutoLogout :warningHeader="$t('home.autoLogoutWarningHeader')"
                                        :warningMessage="$t('home.autoLogoutWarningMessage')"
                                        :warningTime="autoLogoutWarningTime" :logoutTime="autoLogoutTime"
                                        :logoutFn="logoutUser"></AutoLogout>
                            <MultiTabPrevent :notifyFirstTabFn="notifyFirstTab" :notifySecondTabFn="notifySecondTab" />
                            <router-view />
                            <RightDrawer></RightDrawer>
                            <div class="sp-main-footer pull-right">
                                <a href="https://www.sapico.pl" target="_blank">
                                    SAPICO &copy;
                                    {{ new Date().getFullYear() }}
                                    <el-icon><span class="mdi mdi-clock mar-l-8"></span></el-icon>
                                    {{ appVersion }}
                                </a>
                            </div>
                        </div>

                        <el-dialog v-model="operationConfirmDialogVisible" :width="operationConfirmDialogSize"
                                   @close="handleOperationConfirmDialogClose();">
                            <template #header>
                                <span v-html="operationConfirmDialogModel != null ? `${operationConfirmDialogModel.Nazwa} ${operationConfirmDialogModel.selectedRowsCounter}` : ''"></span>
                            </template>
                            <div style="margin-bottom:20px;">
                                <span>
                                    {{ operationConfirmDialogModel != null ? operationConfirmDialogModel.Opis : '' }}
                                </span>
                            </div>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="operationConfirmDialogVisible = false">
                                    {{ $t('buttons.cancel') }}
                                </el-button>
                                <el-button type="primary" class="custom-color"
                                           @click="operationConfirmDialogModel.Run()">
                                    {{ $t('buttons.execute') }}
                                </el-button>
                            </span>
                        </el-dialog>

                        <el-dialog v-model="operationResultsDialogVisible" :close-on-click-modal="false"
                                   :close-on-press-escape="false" :show-close="false" :width="operationResultsDialogSize"
                                   @close="handleOperationConfirmDialogClose();">
                            <template #header>
                                <span class="custom-color">
                                    {{ $t('headers.OperationResult') }}
                                </span>
                            </template>

                            <el-table ref="progressTable" :data="operationResultsDialogModel">
                                <el-table-column prop="OperationName" :label="$t('headers.operation')" min-width="100">
                                </el-table-column>
                                <el-table-column prop="Title" :label="$t('headers.forObject')" min-width="100">
                                    <template #default="props">
                                        <b>{{ Renderers.ellipsis(props.row.Title, 100) }}</b>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="Status" :label="$t('headers.status')" min-width="100">
                                    <template #default="props">
                                        <el-progress :text-inside="true" :stroke-width="18"
                                                     :percentage="props.row.Percentage" :status="props.row.Status"></el-progress>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="Message" :label="$t('headers.info')" min-width="150">
                                    <template #default="props">
                                        <div style="word-break: break-word !important;">
                                            {{ Renderers.ellipsis(props.row.Message, 150) }}
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column type="expand">
                                    <template #default="props">
                                        <p v-html="props.row.ExtendedMessage"></p>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <template #footer>
                                <span class="dialog-footer">
                                    <el-button type="primary" :disabled="!operationResultsDialogModel.allOperationFinished"
                                               @click="operationResultsDialogModel.closeExecution(); operationResultsDialogVisible = false">
                                        {{ $t('buttons.ok') }}
                                    </el-button>
                                </span>
                            </template>
                        </el-dialog>

                        <el-dialog v-model="dialogNewTrancheVisible"
                                   :width="Renderers.dialogSize('50%', '50%', '70%', '90%', '90%', '95%', '50%')"
                                   :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="true">
                            <template #header>
                                <span>{{ $t('buttons.newTranche') }}</span>
                            </template>
                            <div class="sp-client-form-aligned">
                                <el-form :model="contractForm" label-width="100px">
                                    <el-row>
                                        <el-col :span="23">
                                            <el-form-item prop="ContractId" size="mini"
                                                          :label="$t('fields.ContractId')">
                                                <el-select v-model="contractForm.ContractId" class="sp-remotecombo-icon"
                                                           value-key="Name" filterable clearable
                                                           :placeholder="$t('fields.ContractId')"
                                                           @visible-change="(visible) => getDictionaryValues(' ', 'ContractId', '/api/contract/getmediumcombo', null, visible, { MediumType: contractForm.MediumType, CanApplyTranche: true })"
                                                           remote
                                                           :remote-method="query => getDictionaryValues(query, 'ContractId', '/api/contract/getmediumcombo', true, true, { MediumType: contractForm.MediumType, CanApplyTranche: true })"
                                                           :loading="loading">
                                                    <el-option v-for="opt in optionsContractId" :key="opt.Key"
                                                               :label="opt.Name" :value="opt.Key">
                                                        <div style="max-width:430px;">
                                                            {{ opt.Name }}
                                                        </div>
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </div>
                            <template #footer>
                                <span slot="footer" class="dialog-footer">
                                    <el-row>
                                        <el-button @click="dialogNewTrancheVisible = false; contractForm = { ContractId: '', MediumType: '' };">
                                            {{ $t('buttons.cancel') }}
                                        </el-button>
                                        <el-button button type="primary" v-if="contractForm.ContractId"
                                                   @click="openTrancheApplicationForm(contractForm.ContractId, contractForm.MediumType)">
                                            {{ $t('buttons.execute') }}
                                        </el-button>
                                    </el-row>
                                </span>
                            </template>
                        </el-dialog>

                        <el-dialog v-model="dialogNewMandateVisible"
                                   :width="Renderers.dialogSize('50%', '50%', '70%', '90%', '90%', '95%', '50%')"
                                   :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="true">
                            <template #header>
                                <span>{{ $t('buttons.newMandate') }}</span>
                            </template>
                            <div class="sp-client-form-aligned">
                                <el-form :model="contractForm" label-width="100px">
                                    <el-row>
                                        <el-col :span="23">
                                            <el-form-item prop="ContractId" size="mini"
                                                          :label="$t('fields.ContractId')">
                                                <el-select v-model="contractForm.ContractId" class="sp-remotecombo-icon"
                                                           value-key="Name" filterable clearable
                                                           :placeholder="$t('fields.ContractId')"
                                                           @visible-change="(visible) => getDictionaryValues(' ', 'ContractId', '/api/contract/getmediumcombo', null, visible, { MediumType: contractForm.MediumType, CanApplyMandate: true })"
                                                           remote
                                                           :remote-method="query => getDictionaryValues(query, 'ContractId', '/api/contract/getmediumcombo', true, true, { MediumType: contractForm.MediumType, CanApplyMandate: true })"
                                                           :loading="loading">
                                                    <el-option v-for="opt in optionsContractId" :key="opt.Key"
                                                               :label="opt.Name" :value="opt.Key">
                                                        <div style="max-width:430px;">
                                                            {{ opt.Name }}
                                                        </div>
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </div>
                            <template #footer>
                                <span slot="footer" class="dialog-footer">
                                    <el-row>
                                        <el-button @click="dialogNewMandateVisible = false; contractForm = { ContractId: '', MediumType: '' };">
                                            {{ $t('buttons.cancel') }}
                                        </el-button>
                                        <el-button button type="primary" v-if="contractForm.ContractId"
                                                   @click="openMandateApplicationForm(contractForm.ContractId, contractForm.MediumType)">
                                            {{ $t('buttons.execute') }}
                                        </el-button>
                                    </el-row>
                                </span>
                            </template>
                        </el-dialog>

                        <el-dialog v-model="dialogSelectContractVisible"
                                   :width="Renderers.dialogSize('50%', '50%', '70%', '90%', '90%', '95%', '50%')"
                                   :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="true">
                            <template #header>
                                <span>{{ $t('menu.authorization') }}</span>
                            </template>
                            <div class="sp-client-form-aligned">
                                <el-form :model="contractForm" label-width="100px">
                                    <el-row>
                                        <el-col :span="23">
                                            <el-form-item prop="ContractId" size="mini"
                                                          :label="$t('fields.ContractId')">
                                                <el-select v-model="contractForm.ContractId" class="sp-remotecombo-icon"
                                                           value-key="Name" filterable clearable
                                                           :placeholder="$t('fields.ContractId')"
                                                           @visible-change="(visible) => getDictionaryValues(' ', 'AuthContractId', '/api/contract/getcombo', null, visible, {})"
                                                           remote
                                                           :remote-method="query => getDictionaryValues(query, 'AuthContractId', '/api/contract/getcombo', true, true, {})"
                                                           :loading="loading">
                                                    <el-option v-for="opt in optionsAuthContractId" :key="opt.Key"
                                                               :label="opt.Name" :value="opt.Key">
                                                        <div style="max-width:430px;">
                                                            {{ opt.Name }}
                                                        </div>
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </div>
                            <template #footer>
                                <span slot="footer" class="dialog-footer">
                                    <el-row>
                                        <el-button @click="dialogSelectContractVisible = false; contractForm = { ContractId: ''};">
                                            {{ $t('buttons.cancel') }}
                                        </el-button>
                                        <el-button button type="primary" v-if="contractForm.ContractId"
                                                   @click="openContractAuthorizationForm(contractForm.ContractId)">
                                            {{ $t('buttons.execute') }}
                                        </el-button>
                                    </el-row>
                                </span>
                            </template>
                        </el-dialog>
                    </el-main>
                </el-container>
            </el-container>
        </div>
    </el-config-provider>
</template>

<style>
@import './assets/icons/MaterialDesign/css/materialdesignicons.min.css';
@import './assets/css/ElementsUIOverride.css';
@import './assets/css/SapicoHelpers.css';
@import './assets/css/SapicoStyles.css';
@import './assets/css/ScrollBars.css';
@import './assets/css/ClientSite.css';
@import './assets/css/RowColors.css';
@import './assets/css/AppSpecific.css';
@import 'element-plus/theme-chalk/display.css';
</style>

<script>
import $ from "jquery";
import axios from 'axios';
import packageInfo from '../package.json'
import AutoLogout from '@/components/AutoLogout.vue'
import MultiTabPrevent from '@/components/MultiTabPrevent.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import HeaderMenu from '@/components/HeaderMenu.vue'
import RightDrawer from '@/components/RightDrawer.vue'
import { elementLocale, defaultLanguage, changeLanguage, setUserLanguageCookie } from '@/setup/i18n-setup'
import { mapActions, mapState } from 'pinia';
import { useAccountStore } from '@/stores/AccountStore'
import { Helpers } from "@/scripts/helpers";
import { Renderers } from '@/scripts/renderers'
import { Common } from '@/scripts/common'
import { Dictionaries } from '@/scripts/dictionaries'
import { ElMessageBox } from 'element-plus'
import { Notifications } from '@/scripts/notifications'
import { Logger } from '@/scripts/logger'
import * as api from '@/api/ContractApi'
import * as messageApi from '@/api/MessageApi'


export default {
    name: "App",
    mixins: [Common, Dictionaries],
    data() {
        return {
            //elementy strony
            collapseSidebar: false,
            collapseSidebarButtonActive: true,
            collapseSidebarButtonTimeout: 500,
            drawer: false,
            loadingPage: false,
            ellipsisNavbarScreenMaxSize: 0,
            actualScreenSizeResponsive: '',
            actualScreenSize: null,

            //języki
            selectedLanguage: '',
            locale: '',

            //operacje
            operationsController: {},
            operationConfirmDialogExtendedVisible: false,
            operationConfirmDialogVisible: false,
            operationConfirmDialogModel: {},
            operationResultsDialogVisible: false,
            operationResultsDialogModel: {},

            //new tranche/mandate dialog
            dialogNewTrancheVisible: false,
            dialogNewMandateVisible: false,
            contractForm: {
                ContractId: '',
                MediumType: ''
            },
            //authorization
            dialogSelectContractVisible:false,

            //comboboxes
            optionsContractId: [],
            ContractIdSelect: null,
            optionsAuthContractId: [],

            //menu & user info
            menuInformation: [],
            menuInformationEE: {},
            menuInformationGAS: {},
            menuInformationPR: {},
            messageCounter: 0,
            mandateCounter: 0,
            offerCounter: 0,

            autoLogoutWarningTime: process.env.VUE_APP_LOGOUT_WARNING_TIME,
            autoLogoutTime: process.env.VUE_APP_LOGOUT_TIME
        }
    },
    setup() {
        return { Renderers };
    },
    mounted() {
        let self = this;
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.common['accept-language'] = defaultLanguage();

        this.afterPageLoad();
        this.setAxiosAuthorization();

        if (!this.isTokenValid
            && window.location.href.indexOf('login') < 0) {
            window.location = "/login?url=" + window.location.pathname + window.location.search;
        }
        else {
            $('.vue-app').fadeIn(500);
            this.checkUserDataForMenu();

            //self.selectedLanguage = setUserLanguageCookie();
        };

        this.collapseSidebarForSmallScreen();

        this.calculateScreenSize();
        window.addEventListener("resize", this.calculateScreenSize);
    },
    created() {
        if (!this.isTokenValid
            && window.location.href.indexOf('login') < 0) {
            window.location = "/login?url=" + window.location.pathname + window.location.search;
        }

        let self = this;

        self.selectedLanguage = defaultLanguage();
        self.locale = elementLocale();
        self.collapseSidebar = false;

        $(document).on('click', '.sp-client-left-sidebar-menu a', function () {
            self.collapseSidebarForSmallScreen();
        });

        window.onbeforeunload = function () {
            self.beforeUnload();
        };
    },
    computed: {
        ...mapState(useAccountStore, ['user', 'initials', 'beforeUnload', 'isTokenValid',
            'afterPageLoad', 'setAxiosAuthorization']),
        operationConfirmDialogSize: function () {
            return Renderers.dialogSize('30%', '40%', '60%', '70%', '80%', '95%', '70%');
        },
        operationResultsDialogSize: function () {
            return Renderers.dialogSize('50%', '70%', '80%', '90%', '90%', '95%', '70%');
        },
        isMobile: function () {
            var browser = navigator.userAgent;
            if (/mobi/i.test(browser)) {
                return true;
            } else {
                return false;
            };
        },
        appVersion() {
            return packageInfo.version;
        },
    },
    methods: {
        ...mapActions(useAccountStore, ["logout", "finishSession"]),
        calculateScreenSize: function () {
            if (window.innerWidth < 768)
                this.actualScreenSizeResponsive = 'xs';
            else if (window.innerWidth >= 1920)
                this.actualScreenSizeResponsive = 'xl';
            else if (window.innerWidth >= 1200)
                this.actualScreenSizeResponsive = 'lg';
            else if (window.innerWidth >= 992)
                this.actualScreenSizeResponsive = 'md';
            else if (window.innerWidth >= 768)
                this.actualScreenSizeResponsive = 'sm';
            else
                this.actualScreenSizeResponsive = window.innerWidth;

            this.actualScreenSize = window.innerWidth;
        },
        setPL: function () {
            changeLanguage('pl-PL');
            this.selectedLanguage = 'pl-PL';
            this.locale = elementLocale();
            this.loadingPage = true;
            window.location.reload();
        },
        setEN: function () {
            changeLanguage('en-GB');
            this.selectedLanguage = 'en-GB';
            this.locale = elementLocale();
            this.loadingPage = true;
            window.location.reload();
        },
        toggleCollapse: function (hide) {
            if (this.collapseSidebarButtonActive) {
                this.collapseSidebarButtonActive = false;
                this.collapseSidebar = !this.collapseSidebar || hide;

                this.calculateSidebarWidth();

                $('#app').removeClass('sp-collapse-left-sidebar');
                if (this.collapseSidebar) {
                    $('#app').addClass('sp-collapse-left-sidebar');
                };

                setTimeout(() => {
                    this.collapseSidebarButtonActive = true;
                }, this.collapseSidebarButtonTimeout);
            };
        },
        calculateSidebarWidth: function () {
            var roolCollapsedWidth = Helpers.getRootCssValue('--sp-aside-left-collapsed-width', 'int', 'px').ParsedValue;
            var rootFullWidth = Helpers.getRootCssValue('--sp-aside-left-full-width', 'int', 'px').ParsedValue;

            if (this.smallScreen) {
                roolCollapsedWidth = Helpers.getRootCssValue('--sp-aside-left-collapsed-width-smallscreen', 'int', 'vw').ParsedValue;
                var smartScreenFullWidth = Helpers.getRootCssValue('--sp-aside-left-full-width-smallscreen', 'int', 'vw');
                if (smartScreenFullWidth.RawValue == ' 100vw' || smartScreenFullWidth.RawValue == '100vw') {
                    rootFullWidth = window.innerWidth;
                }
                else {
                    rootFullWidth = smartScreenFullWidth.ParsedValue;
                };
            };

            var newWidth = this.collapseSidebar ? roolCollapsedWidth : rootFullWidth;
            $('.sp-client-left-sidebar').animate({
                width: newWidth
            });
            $('.sp-client-left-sidebar-menu').width(newWidth);

            if (newWidth == 0) {
                $('.sp-client-left-sidebar').hide();
                $('.sp-client-left-sidebar').addClass('sp-client-left-hide');
            }
            else {
                $('.sp-client-left-sidebar').show();
                $('.sp-client-left-sidebar').removeClass('sp-client-left-hide');
            };
        },
        collapseSidebarForSmallScreen: function () {
            if (this.smallScreen) {
                this.toggleCollapse(true);
            };
        },
        setTheme: function (element, color) {
            switch (element) {
                case 'header':
                    $('#appLayout').removeClass('sp-client-header-white');
                    $('#appLayout').removeClass('sp-client-header-darkblue');
                    $('#appLayout').removeClass('sp-client-header-red');
                    $('#appLayout').addClass(color);
                    break;
                case 'sidebar':
                    $('#appLayout').removeClass('sp-client-sidebar-transparent');
                    $('#appLayout').removeClass('sp-client-sidebar-gradient-blue');
                    $('#appLayout').removeClass('sp-client-sidebar-gradient-red');
                    $('#appLayout').addClass(color);
                    break;
                case 'box':
                    $('#appLayout').removeClass('sp-client-box-transparent');
                    $('#appLayout').removeClass('sp-client-box-blue');
                    $('#appLayout').removeClass('sp-client-box-red');
                    $('#appLayout').addClass(color);
                    break;
                default:
                    return;
            };
        },
        openTrancheApplicationForm: function (contractId, mediumType) {
            if (contractId && mediumType)
                this.$router.push({ name: 'trancheapplication', params: { contractId: contractId, medium: mediumType, searchModel: JSON.stringify({ 'MediumType': mediumType }) } });
            this.dialogNewTrancheVisible = false;
            this.collapseSidebarForSmallScreen();
        },
        openMandateApplicationForm: function (contractId, mediumType) {
            if (contractId && mediumType)
                this.$router.push({ name: 'mandateapplication', params: { contractId: contractId, medium: mediumType, searchModel: JSON.stringify({ 'MediumType': mediumType }) } });
            this.dialogNewMandateVisible = false;
            this.collapseSidebarForSmallScreen();
        },
        openContractAuthorizationForm: function (contractId) {
            if (contractId != null && contractId != '') {
                this.$router.push({ name: 'contractform', params: { id: contractId }, hash: '#ContractAuthoriseForm' });
            }
            this.dialogSelectContractVisible = false;
            this.collapseSidebarForSmallScreen();
        },
        notifyFirstTab() {
            return;
        },
        notifySecondTab() {
            ElMessageBox.alert(
                this.$t("home.multiTabMessage"),
                this.$t("home.multiTabHeader"),
                {
                    type: 'error',
                    confirmButtonText: 'OK',
                    callback: (action) => {
                        window.location = "about:blank";
                    },
                });
            setTimeout(() => {
                ElMessageBox.close();
                window.location = "about:blank";
            }, 6 * 1000);
        },
        checkUserDataForMenu() {
            this.getMenuInformation();
            this.getMessageCounter();
        },
        getMenuInformation() {
            let self = this;

            api.getMenuInformation(
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        return;
                    };

                    self.menuInformation = response.data.Model;
                    self.menuInformationEE = self.menuInformation?.filter(f => { return f.MediumType === 'EE'; })[0];
                    self.menuInformationGAS = self.menuInformation?.filter(f => { return f.MediumType === 'GAS'; })[0];
                    self.menuInformationPR = self.menuInformation?.filter(f => { return f.MediumType === 'PR'; })[0];
                    self.offerCounter = self.menuInformation?.reduce(function (s, a) { return s + a.HasOffers ?? 0; }, 0);
                    self.mandateCounter = self.menuInformation?.reduce(function (s, a) { return s + a.HasMandates ?? 0; }, 0);
                },
                (error) => {
                    Logger.logException(error);
                }
            );
        },
        getMessageCounter() {
            let self = this;

            messageApi.getPage({
                searchModel: JSON.stringify({}),
                pageIndex: 1,
                pageSize: 10000
            },
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        this.loading = false;
                        return;
                    }

                    this.messageCounter = response.data.Model.data.filter(x => !x.IsRead).length;

                    this.loading = false;
                },
                (error) => {
                    Logger.logException(error);

                    this.loading = false;
                }
            );
        },
        handleOperationConfirmDialogClose() {
            if (this.operationConfirmDialogModel?.caller) {
                this.operationConfirmDialogModel.caller.showDlgOperation = false;
            };
        },
        loginUser() {
            window.location = "/login";
        },
        logoutUser() {
            this.finishSession((success => {
                this.logout();
                window.location = "/login";
            }, error => {
                this.logout();
                window.location = "/login";
            }));


        },
        changePassword() {
            window.location = "/changePassword?login=" + this.user?.login;
        },
    },
    components: {
        SidebarMenu,
        HeaderMenu,
        RightDrawer,
        AutoLogout,
        MultiTabPrevent
    },
    watch: {

    }
}
</script>