import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import { createPinia } from 'pinia'
import "element-plus/theme-chalk/index.css"
import { i18n } from './setup/i18n-setup.js'
import { Logger } from './scripts/logger'
import { Helpers } from './scripts/helpers'
import dayjs from 'dayjs'

// set first day of week to Monday
dayjs.Ls.en.weekStart = 1

const app = createApp(App)

app.config.errorHandler = function (err, vm, info) {
    Logger.logException(err);
}

app.use(i18n)
    .use(router)
    .use(createPinia())
    .use(ElementPlus)
    .mount('#app');


app.directive('numberdecimal', (el, binding) => {
    const decimalPlaces = binding.value > 0 ? binding.value : (binding.value.decimalPlaces ?? 2);
    const maxLen = binding.value.maxLen ?? 12;
    const totalLength = decimalPlaces + (decimalPlaces > 0 ? 1 : 0) + maxLen; //max. 15 znaków

    el.addEventListener('keydown', (e: any) => {
        var value = e.target.value;
        const keyCode = (e.keyCode ? e.keyCode : e.which);
        const i = value.indexOf('.') > 0 ? value.indexOf('.') : value.indexOf(',');
        const separator = value.indexOf('.') > 0 ? '.' : value.indexOf(',') > 0 ? ',' : null;

        //console.log(keyCode, value, i, separator, decimalPlaces, totalLength);

        //restrict length
        if (!Helpers.isOperationKey(e) && value.length >= totalLength) {
            e.preventDefault();
        };
        //allow only numbers and operation buttons
        if (!Helpers.isDecimalNumber(e)) {
            e.preventDefault();
        }
        //separator exists
        else if (i > 0 && (keyCode == 44 || keyCode == 46 || keyCode == 110 || keyCode == 188 || keyCode == 190)) {
            e.preventDefault();
        }
        //decimalPlaces = 0, separator not allowed
        else if (decimalPlaces == 0 && (keyCode == 44 || keyCode == 46 || keyCode == 110 || keyCode == 188 || keyCode == 190)) {
            e.preventDefault();
        }

        //replace coma to dot
        // if ((keyCode === 188 || keyCode === 110) && i < 0) {
        //     e.target.value += ".";
        //     e.target.dispatchEvent(new Event('input'));
        //     e.preventDefault();
        // };

    });

    el.addEventListener('paste', (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        var clipboardData = e.clipboardData;
        var pastedData = clipboardData.getData('Text');

        var pastedValue = pastedData.replace(',', '.');
        pastedValue = Number(pastedValue) ? Number(pastedValue).toFixed(decimalPlaces) : '';
        //console.log(pastedValue);

        e.target.value = pastedValue;
        e.target.dispatchEvent(new Event('input'));
    });

    // modify the initial value if it's not 2dp
    //const inputElement = el.nodeName == 'INPUT' ? el : el.querySelector('input');
    // if (Number(inputElement.value)) {
    //     const newValue = (Number(inputElement.value) || 0).toFixed(2);
    //     if (inputElement.value != newValue) {
    //         inputElement.value = newValue;
    //         inputElement.dispatchEvent(new Event('input'));
    //     }
    // };

});